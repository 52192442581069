import * as React from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import { Link } from "gatsby";
import adorno from '@images/adorno/adorno.png';
import lupa from '@images/objetivos/obj3/lupa.png';
import libros from '@images/objetivos/obj3/tablet-svgrepo-com.svg';
import hier from '@images/objetivos/obj3/hierarchy-levels-svgrepo-com.svg';

const Wrapper = styled.div`
height:100%;
padding-top: 1rem;
text-align: justify;
display:flex;
flex-direction: column;
align-items: flex-end;
`;



const IndexPage = () => {
    return (
        <TemplatePage1
            title={"Making the pertinent research and of high impact for LAC"}
            showtitle={false}
            adorno={<Adorno/>}
            content={(
                <Wrapper >
                    <TitleGroup>
                        <div>
                            <h1>Goal</h1>
                            <h2>3</h2>
                        </div>
                        <p>
                        Making the pertinent research and of high impact for LAC and sharing the evidence with the local politicians who are responsible.
                        </p>
                    </TitleGroup>
                    <CardContainer>
                    <Card>
                       
                            <img className={"Thumb"} src={lupa} alt={lupa}/>
                        
                            Development a portfolio of projects of research of quality of Health Systems to gather evidence. 
                    </Card>
                    <Card left>
                   
                            <img className={"Thumb "} src={lupa} alt={lupa}/>
                        
                        
                            <img className={"th-left"} src={libros} alt={libros}/>
                            Contribute to the analisis and presentation of reports from  People's Voice Survey - PVS ("Voz de la Gente")
                            <br/><br/>
                            Contribute to the analisis and presentation of reports from E-cohorts

                            <div>
                            <Link to={"/proyectos/enmarcha"}>Ver proyectos</Link>
                                </div>
                            
                            
                       
                    </Card>
                    <Card>
                    
                            <img className={"Thumb"} src={lupa} alt={lupa}/>
                            <img className={"th-right"} src={hier} alt={hier}/>
                            Create links to decision makers and nationa authorities and regionals in order to proomote evidence baed policies.
                            
                    </Card>
                    </CardContainer>
                </Wrapper >
            )}
        />
    )
};

export default IndexPage


const CardContainer = styled.div`
margin-left: 10px;
border-left: 5px gray solid;
display:flex;
flex-direction: column;
align-items: flex-end;
`;

const Card = styled.div`
padding: 5px;
background-color: white;
padding-left: 20px;
margin-top:15px;
margin-bottom:15px;
width:100%;

box-shadow: 5px 5px 5px black;
@media only screen and (min-width:900px){
    transform: ${props=>props.left?"translateX(-320px)":""};
    max-width: 300px;
}


:hover{
    .Thumb{
        display:flex;
    position: absolute;
    height:30px;
    width:30px;
    transform: translateX(-50px);
    @media only screen and (min-width:900px){
        transform: ${props=>props.left?"translateX(270px)":"translateX(-50px)"};
}
}
}

div{
    display: flex;
    flex-direction: column;

    a{
    padding:10px;
    margin:10px auto;
    color: white;
    background-color: var(--tema4);
    cursor: pointer;
}
}



.Thumb{
    display:none;
}

img{
    width:70px;
    margin:10px;
    height:70px;

}

.th-left{
    float: left;
}

.th-right{
    float: right;
}


`;


const TitleGroup = styled.div`
max-width: 600px;



display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: right;


div{
  display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
  h1{
    color: var(--tema4);
  font-size: 18px;
  margin:0;
}

h2{
  font-size: 100px;
  line-height: 100px;
  color:white;
  font-weight: bold;
  text-shadow: 0px 0px 4px var(--tema4);
  margin:0;
}
}
p{
  font-size: 24px;
  font-weight: bold;
  color: var(--tema4);
}

`;




const Adorno = styled.div`
position: absolute;
top: 0;
left: 0;
height:110%;
@media only screen and (min-width:960px){
  height:110%;
  width: 40%;
  background-position-x:-400px;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  height:110%;
  width: 40%;
  background-position-x:-200px;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
@media only screen and (min-width:1430px){
  height:110%;
  width: 40%;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
@media only screen and (min-width:1700px){
  height:110%;
  width: 50%;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
`;